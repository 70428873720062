export const texts = {
  // Common
  '': '',
  'common.decktopus': 'Decktopus',
  'common.decks': 'Decks',
  'common.free': 'Free',
  'common.description': 'Description',
  'common.name': 'Name',
  'common.email': 'email',
  'common.full_name': 'full name',
  'common.role': 'Role',
  'common.dashboard': 'Dashboard',
  'common.analytics': 'Analytics',
  'common.responses': 'Responses',
  'common.read_only': 'Read only',
  'common.read_write': 'Read & Write',
  'common.last': 'Last',
  'common.first': 'First',
  'common.title_asc': 'Title (A-Z) ',
  'common.title_desc': 'Title (Z-A) ',
  'common.recent': 'Recently Modified ',
  'common.oldest': 'Oldest Modified ',
  'common.personal': 'Personal',
  'common.date': 'Date',
  'common.auto': 'Auto',
  'common.rate': 'Rate',
  'common.text': 'Text',
  'common.value': 'Value',
  'common.empty': 'Empty',
  'common.or': 'or',
  'common.and': 'and',
  'common.email_address': 'Email Address',
  'common.password': 'Password',
  'common.organization': 'Organization',
  'common.free_uppercased': 'FREE',
  'common.design': 'Design',
  'common.access': 'Access',
  'common.required': 'Required',
  'common.button': 'Button',
  'common.template_name': 'Template Name',
  'common.language': 'Language',
  'common.texts': 'Texts',
  'common.history': 'History',
  'common.percent': '{{value}}%',

  'common.files': '{{count}} files',
  'common.files_zero': '{{count}} file',
  'common.files_one': '{{count}} file',
  'common.files_other': '{{count}} files',

  'common.slides': '{{count}} slides',
  'common.slides_zero': '{{count}} slide',
  'common.slides_one': '{{count}} slide',
  'common.slides_other': '{{count}} slides',

  'common.actions.change': 'Change',
  'common.actions.close': 'Close',
  'common.actions.complete': 'Complete',
  'common.actions.back': 'Back',
  'common.actions.reset': 'Reset',
  'common.actions.delete': 'Delete',
  'common.actions.create': 'Create',
  'common.actions.share': 'Share',
  'common.actions.search': 'Search',
  'common.actions.search_with_name': 'Search {{name}}',
  'common.actions.check': 'Check',
  'common.actions.connect': 'Connect',
  'common.actions.export': 'Export',
  'common.actions.download': 'Download',
  'common.actions.sort_by': 'Sort by',
  'common.actions.edit': 'Edit',
  'common.actions.open': 'Open',
  'common.actions.save': 'Save',
  'common.actions.cancel': 'Cancel',
  'common.actions.add': 'Add',
  'common.actions.remove': 'Remove',
  'common.actions.rename': 'Rename',
  'common.actions.discard': 'Discard',
  'common.actions.approve': 'Approve',
  'common.actions.next': 'Next',
  'common.actions.move_to': 'Move to',
  'common.actions.play': 'Play',
  'common.actions.duplicate': 'Duplicate',
  'common.actions.activate': 'Activate',
  'common.actions.save_changes': 'Save Changes',
  'common.actions.paste': 'Paste',
  'common.actions.upload': 'Upload',
  'common.actions.submit': 'Submit',
  'common.actions.listen_voice': 'Listen Voice',
  'common.actions.record_voice': 'Record Voice',
  'common.actions.lets_start': `Let's Start`,
  'common.actions.leave': 'Leave',
  'common.actions.upload_picture': 'Upload Picture',
  'common.actions.remove_picture': 'Remove Picture',
  'common.actions.select': 'Select',
  'common.actions.preview': 'Preview',
  'common.actions.skip': 'Skip',
  'common.actions.set_as_default': 'Set as default',

  'common.informative.copied': 'Copied!',
  'common.informative.click_here': 'click here.',
  'common.informative.coming_soon': 'Coming Soon',
  'common.informative.fail': 'Fail',
  'common.informative.processing': 'Processing',
  'common.informative.thanks': 'Thanks!',
  'common.informative.done': 'Done',
  'common.informative.payment_successful': 'Payment Successful',
  'common.informative.your_deck_is_ready': 'Your deck is ready.',
  'common.informative.ai_loading_text':
    'Crafting your masterpiece, just a moment...',
  'common.informative.ai_error_text':
    "We're experiencing exceptionally high demand.",
  'common.informative.wait_on_dashboard': 'Wait on Dashboard',
  'common.informative.back_to_first': 'Back to First',
  'common.informative.ai_high_demand_text':
    "Your deck could not be created. We're experiencing exceptionally high demand.",
  'common.informative.error_start_recording': 'Error starting recording:',
  'common.informative.error_stop_recording': 'Error stopping recording:',
  'common.informative.error_changing_language':
    'Problem occured while changing language, please contact our customer support',
  'common.informative.error_select_a_style':
    'Please choose or create a style before generating an image.',
  'common.informative.large_media_file':
    'Oops! Your media file is over 5MB. Please select a smaller file for a smoother experience.',
  'common.informative.captcha_error':
    'Captcha verification failed, please try again.',
  'common.informative.captcha_expired': 'Captcha expired, please try again',
  'common.informative.captcha_challange_expired':
    'Captcha challenge expired, please try again.',

  'common.directions.left': 'Left',
  'common.directions.middle': 'Middle',
  'common.directions.right': 'Right',
  'common.directions.up': 'Up',
  'common.directions.center': 'Center',
  'common.directions.down': 'Down',

  'common.nth': '{{count}}th',
  'common.nth_one': '{{count}}st',
  'common.nth_two': '{{count}}nd',
  'common.nth_three': '{{count}}rd',
  'common.nth_other': '{{count}}th',

  'common.files.media': 'Media',
  'common.files.video': 'video',
  'common.files.image': 'Image',
  'common.files.gif': 'Gif',
  'common.files.icon': 'Icon',
  'common.files.powerpoint': 'PowerPoint',
  'common.files.pdf': 'PDF',
  'common.files.pdf_standard': 'PDF Standard',
  'common.files.pdf_compressed': 'PDF Compressed',
  'common.files.ppt_standard': 'PPT Standard',
  'common.files.ppt_compressed': 'PPT Compressed',

  'common.roles.owner': 'Owner',
  'common.roles.admin': 'Admin',
  'common.roles.member': 'Member',
  'common.roles.pending': 'Pending',

  'common.plan_period.monthly': 'Monthly',
  'common.plan_period.annually': 'Annual',

  'common.plan_card.month': ' / month',

  'common.seats': 'seat',
  'common.seats_zero': 'seat',
  'common.seats_one': 'seat',
  'common.seats_other': 'seats',

  'common.deck.add_slide': 'Add Slide',
  'common.deck.duplicate_slide': 'Duplicate Slide',
  'common.deck.delete_slide': 'Delete Slide',
  'common.deck.undo': 'Undo',
  // Common ends

  // Login page
  'login.welcome': 'Welcome!',
  'login.session_expired': 'Session expired, please login again.',
  'login.continue_with_google': 'Continue with Google',
  'login.login': 'Log in',
  'login.logout': 'Logout',
  'login.signup': 'Sign up',
  'login.dont_have_an_account': `Don't have an account?`,
  'login.forgot_password': 'Forgot your password?',
  'login.title1': '3 Mn+',
  'login.text1': 'Users around the globe',
  'login.title2': '64%',
  'login.text2': 'Time saved by preparing presentations with AI',
  'login.title3': '5 Mn+',
  'login.text3': 'Presentations created with Decktopus',
  'login.title4': '<1 min',
  'login.text4': 'Average customer support response time',
  'login.incorrect_provider_jwt': `It looks like you've already signed up with an email and password. Please log in using those credentials.`,
  'login.incorrect_provider_google': `It looks like you've already signed up with Google account. Please try Google login to continue.`,
  'login.verify': 'Verify',
  'login.enter_six_digit_code':
    'Enter the 6-digit code from your authenticator app to complete the login process.',
  'login.auth_code': 'Authentication Code',
  'login.use_backup_code': 'Use backup code',
  'login.enter_one_of_your_backup_codes':
    'Enter one of your backup codes to log in.',
  'login.use_authenticator_code': 'Use authenticator code',
  'login.backup_code': 'Backup Code',
  // Login page ends

  // Expired page
  'expired.get_started': 'Get Started',
  'expired.expire_link_title': 'This link is expired',
  'expired.expire_link_description':
    'This link is no longer valid. Please get in touch with your organization for further information.',
  // Expired page ends

  // Sign up page
  'signup.terms': `Terms`,
  'signup.privacy_policy': `Privacy Policy`,
  'signup.full_name': `Full Name`,
  'signup.already_member': `Already a member?`,
  'signup.create_account': 'Create Account',
  'signup_page_title': 'Create your account and unlock your presentation',
  'signup.create_account_info': `Creating an account means you're okay with our <0>terms</0> and <1>privacy</1>`,
  'signup.banner': 'You are one click away from your presentation',
  // Sign up page ends

  // Reset password
  'reset.password_reset': 'Password Reset',
  'reset.back_to': 'Back to',
  'reset.send_email': 'Send Email',
  'reset.resend': 'Resend Email',
  'reset.new_password': 'New Password',
  'reset.password_set': 'Your password is changed, please login.',
  'reset.reset_password': 'Reset password',
  'reset.reset_password_info': `Enter your email, and we'll send you the instructions on how to reset your password`,
  'reset.password_reset_sent': `Your password reset email has been sent`,
  'reset.change_your_password_info': 'Click the button to reset your password',
  // Reset password ends

  // Auth pages common
  'auth.site_protection': `This site is protected by hCaptcha and its <0>Privacy Policy</0> and <1>Terms of Service</1> apply.`,
  'auth.terms_of_service': 'Terms of Service',
  // Auth pages common ends

  // Not supported page
  'not_supported': 'not supported',
  'not_supported.title':
    'Unfortunately, the edit mode is not supported on this device yet. For the best experience, please switch to a desktop computer.',
  //

  // Webhook
  'webhook.endpoint_url': 'Your Endpoint URL',
  'webhook.event_form_responses': 'Event: Form Responses',
  'webhook.webhook_delete_warning':
    'Are you sure that you want to delete permanently “<strong>{{name}}</strong>” webhook?',
  'webhook.url': 'URL',
  'webhook.webhook': 'Webhook',
  'webhook.create_webhook': 'Create Webhook',
  'webhook.webhook_form_general_info':
    'Create a webhook and send real-time notifications when any of your decks get a form submission. Add your endpoint URL and name your new webhook.',
  'webhook.webhook_learn':
    'To learn more about how to use webhooks <0>click here.</0>',
  // Webhook ends

  // Main panel tabs
  'main_panel.admin': 'Admin',
  'main_panel.organization': 'Organization',
  'main_panel.profile_settings': 'Profile Settings',
  'main_panel.webhook': 'Webhook',
  'main_panel.refer_and_earn': 'Refer & Earn',
  'main_panel.subscription': 'Subscription',
  'main_panel.onboarding': 'Onboarding',
  'main_panel.logout': 'Logout',
  // Main panel tabs ends

  // Profile settings
  'profile.change_account_information': 'Change Account Information',
  'profile.edit_your_name': 'Edit your name',
  'profile.change_your_password': 'Change your password',
  'profile.update.save_changes': 'Save Changes',
  'profile.update.image_upload_success': 'Your profile picture has changed.',
  'profile.update.name_change_success': 'Your name has changed.',
  'profile.update.name_min_char_error':
    'Name can not be shorter than 3 characters.',
  'profile.update.image_upload_error': '{{fileType}} is not supported.',
  'profile.verification_success':
    'You have successfully verified your account.',
  'profile.enable_2fa': 'Enable Two-Factor Authentication',
  'profile.use_an_authenticator':
    'Use an authenticator app like Google Authenticator, Microsoft Authenticator, Authy, or iPassword to scan this QR code. It will generate a 6-digit code for you to enter below.',
  'profile.keep_your_backup':
    'Keep your backup codes safe and accessible in case you need them to regain access to your account.',
  'profile.enter_auth_code': 'Enter Authentication Code',
  'profile.generate_new_codes':
    'Generate a new set of authentication codes if you need to replace your current ones.',
  'profile.get_new_backup_codes': 'Get New Backup Codes',
  'profile.continue': 'Continue',
  // Profile settings ends

  // Verify email
  'verify.verify_your_email': 'Verify your email',
  'verify.verify_your_account': 'Verify your account',
  'verify.verify_your_email_info': 'Click the button to verify your account.',
  'verify.verification_email_sent': 'Verification mail sent.',
  'verify.verification_enter_code': 'Enter your code',
  'verify.verification_didnt_reveive_email': `Didn't receive the email? <0>link</0>`,
  'verify.verification_click_to_resend': 'Click to resend',
  'verify.verification_email_sent_info':
    'We sent a verification code to your email.',
  // Verify email ends

  // Validations
  'validation.min_char':
    '{{name, capitalFirstLetter}} can not be shorter than {{value}} characters.',
  'validation.max_char':
    '{{name, capitalFirstLetter}} can not be longer than {{value}} characters.',
  'validation.error.required': '{{name, capitalFirstLetter}} can not be empty',
  'validation.error.email': 'Invalid email address',
  'validation.error.url': 'Invalid URL address',
  'validation.error.video_url':
    'Invalid video url. Please write youtube or vimeo link.',
  'validation.validation_user_exists': 'This user already exists',
  // Validations ends

  // Layout modal
  'layout.save': 'Save Layout',
  'layout.save_new': 'Save as New Layout',
  'layout.name': 'Layout Name',
  'layout.blank': 'Blank',
  // Layout modal ends

  // Workspace selector
  'workspace.personal_hub': 'Personal Hub',
  // Workspace selector ends

  // Init modal
  'init.category_select_info':
    'Choose a design category that fits your presentation concept',
  'init.see_templates': 'See Templates',
  'init.start_from_scratch': 'Start from Scratch',
  'init.use_this_template': 'Use This Template',

  'init.category0': 'All',
  'init.category1': 'Business',
  'init.category2': 'Proposal',
  'init.category3': 'Online Course',
  'init.category4': 'Education',
  'init.category5': 'Community',
  'init.category6': 'Nutrition',
  'init.category7': 'Executive Summary',
  'init.category8': 'Case Study',
  'init.category9': 'Startup Pitch',
  // Init modal ends

  // Create with AI modal
  'create_with_ai.generate_presentation': 'Generate Presentation',
  'create_with_ai.create_with_ai': 'Create with AI',
  'create_with_ai.prompt_placeholder': 'Write your presentation topic here!',
  'create_with_ai.audience_placeholder': 'Write your audience',
  'create_with_ai.aim_placeholder': 'Write your aim',
  'create_with_ai.prompt_title': 'What’s your presentation about? ',
  'create_with_ai.audience_title': 'Who is your audience?',
  'create_with_ai.aim_title': 'What is your aim for this deck?',
  'create_with_ai.outline_title':
    'You can modify the outline or continue without changing it.',
  'create_with_ai.theme_title': 'Which template would you like to use?',
  'create_with_ai.theme_subtitle': ' Don’t worry! You can change it anytime.',
  'create_with_ai.ai_subtitle':
    'Inspire Deckto with a title. Try typing your presentation topic like: “History of Rome”',
  'create_with_ai.see_examples': 'See Examples',
  'create_with_ai.hide_examples': 'Hide Examples',
  'create_with_ai.import_pdf': 'Import your PDF',
  'create_with_ai.pdf_subtitle':
    'Deckto will transform your document into a deck with edited slides.',
  'create_with_ai.category_title': 'Choose a category for your presentation.',
  'create_with_ai.category.option1': 'Startup Pitch',
  'create_with_ai.category.option2': 'Business Proposal',
  'create_with_ai.category.option3': 'Sales Presentation',
  'create_with_ai.category.option4': 'Report Presentation',
  'create_with_ai.category.option5': 'Educational',
  'create_with_ai.category.option6': 'Other',
  'create_with_ai.strength.low':
    'A few more words can unlock a world of deeper insights!',
  'create_with_ai.strength.medium':
    'You’re halfway there—add a touch more to reveal the full picture!',
  'create_with_ai.strength.high':
    'Fantastic! The more context you provide, the sharper the results.',
  'create_with_ai.strength.done':
    "Awesome! Feel free to add more if you'd like!",
  'create_with_ai.strength.tooltip':
    'Detailed prompts lead to sharper, more relevant insights—give us more context to get the best results!',
  // Create with AI modal ends

  // File import modal
  'file_import.import_file_title': '(Optional) Upload your documents',
  'file_import.import_file_info':
    'Upload any documents to include or reference in your presentation.',
  'file_import.use_file_format':
    'Can’t upload the {{fileName}} file. Use a file in the following formats: docx, json, md, pdf, pptx, txt',
  'file_import.maximum_files': 'You can upload a maximum of 5 files',
  'file_import.maximum': 'You have reached the maximum file upload limit!',
  'file_import.maximum_info': 'Please delete one to upload another one.',
  'file_import.drag_and_drop': 'Drag your files or browse',
  'file_import.size_error':
    'The {{fileName}} file is too large. Try a smaller one.',
  'file_import.already_uploaded':
    'The {{fileName}} file is already uploaded. Try another one.',
  'file_import.file_state.ready': 'Ready',
  'file_import.file_state.error': 'Error',
  'file_import.file_state.processing': 'Processing',
  'file_import.file_state.ready_info': 'File uploaded successfully.',
  'file_import.file_state.error_info':
    'Sorry, an unexpected error occurred. Please try uploading another file.',
  'file_import.file_state.processing_info':
    'The file is being processed, please wait.',
  // File import modal ends

  // Organization page
  'organization.organization_profile_update.image_upload_success':
    'Organization picture has changed.',
  'organization.organization_profile_update.name_and_description_success':
    'Organization details have changed.',
  'organization.organization_details': 'Organization Details',
  'organization.remaining_seats': 'Remaining seats',
  'organization.manage_seats': 'Manage seats',
  'organization.used_seats': '{{occupiedSeats}} of {{totalSeats}} seats used',
  'organization.adjust_seat_number': 'Adjust seat number',
  'organization.current_users': 'Current Users ({{count}}) Cost / 12',
  'organization.added_users': 'Added Users ({{count}}) Cost / 12',
  'organization.updated_annual_total': 'Updated {{period}} Total Cost / 12',
  'organization.first_charge': 'The first charge will be prorated.',
  'organization.first_charge_tooltip':
    'The first charge of the additional seats will be calculated according to the number of days left in your current billing cycle.',
  'organization.reset_invitation': 'Resent invitation',
  'organization.cancel_invitation': 'Cancel invitation',
  'organization.member_settings': 'Member Settings',
  'organization.add_members': 'Add members',
  'organization.add_members_title': 'Add members to your organization',
  'organization.not_enough_seats':
    'You do not enough seats to add more members. Adjust seat number from organization page to make room.',
  'organization.add_member_information':
    'You can always invite members to your organization from right bar in “My Organization” page.',
  'organization.organization-details': 'Organization Details',
  'organization.leave_team_title': 'Leave Team',
  'organization.leave_team_button': 'Leave the organization',
  'organization.leave_team_dialog_text':
    'By leaving an organization you can no longer access collaborated decks.',
  'organization.leave_team_text':
    'If you leave the team, you will not be able to access organization contents and business features.',
  'organization.organization_name_label': 'Organization name',
  'organization.organization_desc_label': 'Organization Description',
  'organization.organization_team_logo': 'Team Logo',

  'organization.delete_member': 'Delete Member',
  'organization.delete_member_title':
    'Are you sure you want to delete permanently  “{{name}}”?',
  'organization.delete_member_text': `“{{name}}” will not be able to see all decks and organization details`,

  'organization.resend_info':
    '{{email}} has not accepted your invitation yet. You will resend the invitation as a reminder. ',
  'organization.organization_suspended':
    'This organization is suspended. Kindly update your payment method to resolve the issue.',
  'organization.organization_suspended_member':
    'This organization is suspended. Please reach out to the organization admin for further assistance.',
  // Organization page ends

  // Refer
  'refer.invite_friend': 'Invite your friend!',
  'refer.invite_friend_text': `Both you and your friend get <strong>30</strong> credits.`,
  'refer.referred': 'We sent your friend an email',
  // Refer ends

  // Analytics
  'analytics.no_views_yet': 'No views yet',
  'analytics.share_this_deck': 'Share this deck.',
  'analytics.total_views': '{{view}} Total Views',
  'analytics.total_views_one_slide':
    '{{view}} Views for the very first slide only',
  'analytics.chart_description':
    'This chart represents your decks view performance',
  'analytics.last_time_open': 'Last time open',
  'analytics.views': '{{view}} Views',
  'analytics.openedTimes': 'Opened {{view}} times',
  // Analytics ends

  // Subscriptions
  'subscriptions.upgrade': 'Upgrade',
  'subscriptions.show_more': 'Show more',
  'subscriptions.show_less': 'Show less',
  'subscriptions.subscription': 'Subscription',
  'subscriptions.current_plan': 'Current Plan',
  'subscriptions.credit_information': 'Credit Information',
  'subscriptions.ai_credits': 'AI Credits',
  'subscriptions.choose_plan': 'Choose Plan',
  'subscriptions.plan_renew_info': 'Your plan renews on {{date}}',
  'subscriptions.plan_cancel_info': 'Your plan will be canceled on {{date}}',
  'subscriptions.plan_renew_info_free':
    'To access amazing ai features upgrade your account',
  'subscriptions.credit_renew_info': 'Your credits will be renewed on {{date}}',
  'subscriptions.credit_cancel_info': 'Your credits can be used until {{date}}',
  'subscriptions.credit_history': 'Credit History',
  'subscriptions.credit_history_info':
    'Credit history reflects spending up to the beginning of the subscription period',
  'subscriptions.subscription_settings': 'Subscription Settings',
  'subscriptions.change_payment_method': 'Change Payment Method',
  'subscriptions.update_billing_info': 'Update Billing Information',
  'subscriptions.invoices': 'Invoices',
  'subscriptions.cancel_subscription': 'Cancel Subscription',
  'subscriptions.renew_subscription': 'Renew Subscription',

  'subscriptions.cancel_promo_modal.title': '75% DISCOUNT FOR 3 MONTHS',
  'subscriptions.cancel_promo_modal.subtitle':
    'We’ve noticed you’re about to cancel your subscription',
  'subscriptions.cancel_promo_modal.description':
    'Since you’re a valued user, we would like to offer you an exclusive <strong>{{discount}}</strong> on your next {{period}} of subscription if you choose to stay with Decktopus.',
  'subscriptions.cancel_promo_modal.cancel': 'Cancel my subscription',
  'subscriptions.cancel_promo_modal.apply': 'Apply Discount',
  'subscriptions.cancel_promo_modal.success':
    'Your discount is applied successfully',
  'subscriptions.cancel_promo_modal.discount_monthly': '75% discount',
  'subscriptions.cancel_promo_modal.discount_annually': '25% discount',
  'subscriptions.cancel_promo_modal.period_monthly': '3 months',
  'subscriptions.cancel_promo_modal.period_annually': 'year',

  'subscriptions.cancel_reason_dialog.title':
    'What could we have done differently to improve your experience?',
  'subscriptions.cancel_reason_dialog.subtitle': '50 character min',
  'subscriptions.cancel_reason_dialog.submit': 'Send feedback',
  'subscriptions.cancel_reason_dialog.input_placeholder':
    'Type your experience here.',
  'subscriptions.cancel_reason_dialog.input_label': 'Feedback',
  'subscriptions.cancel_reason_dialog.footer_text':
    'Your feedback is incredibly valuable to us. It helps us improve and create a better experience for you and others in the future. Even the smallest insights can make a big difference. Thank you for sharing!',

  'subscriptions.plan.powerup_info': 'I want to power up my {{plan_name}}',
  'subscriptions.plan.choose_lite': 'Choose Lite',
  'subscriptions.plan.choose_pro': 'Choose Pro',
  'subscriptions.plan.choose_business': 'Choose Business',
  'subscriptions.plan.discount_info': 'Save up to 40%',

  'subscriptions.subs_state.pending': 'Pending',
  'subscriptions.subs_state.action_required': 'Action Required',
  'subscriptions.subs_state.failed': 'Failed',
  'subscriptions.subs_state.canceled': 'Canceled',
  'subscriptions.subs_state.active': 'Active',
  'subscriptions.plan.popular': 'Popular',
  'subscriptions.billed_annually': ' billed annually',
  'subscriptions.update_payment': 'Update Payment Method',
  'subscriptions.unlimited': 'Unlimited',

  // Subscriptions ends

  // Upgrade modal
  'upgrade_modal.upgrade_to_pro_or_business': 'Upgrade to Pro or Business',
  'upgrade_modal.upgrade_to_business': 'Upgrade to Business',
  'upgrade_modal.upgrade_to_business_text':
    'Level up your presentations and increase your ROI on presentations.',
  'subscriptions.plan.cancel_anytime': 'Cancel anytime',
  'upgrade_modal.refill_your_credits': 'Refill your credits',
  'upgrade_modal.upgrade_refill_modal_subtitle':
    'Keep your momentum. Upgrade your plan to keep working.',
  'upgrade_modal.upgrade_modal_subtitle':
    'Use Decktopus as your AI co-pilot for presentations.',
  'upgrade_modal.choose_plan': 'Choose a Plan',
  // Upgrade modal ends

  // Dashboard page
  'dashboard.create.create_presentation': 'Create presentation',
  'dashboard.create.start_from_scratch': 'Start from scratch',
  'dashboard.create.create_with_a_template': 'Create with a template',

  'dashboard.folder.folder': 'Folder',
  'dashboard.shared_with_me': 'Shared with me',
  'dashboard.folder.create_new_folder': 'Create New Folder',
  'dashboard.folder.create_folder': 'Create Folder',
  'dashboard.folder.delete_title':
    'Are you sure you want to delete permanently  “{{folder_name}}” and {{folder_id}} files in it?',
  'dashboard.folder.delete_text': `Files can’t be recovered once deleted.`,
  'dashboard.folder.folder_is_created': 'Folder is created.',
  'dashboard.folder.folder_deleted': 'Folder is deleted.',
  'dashboard.folder.type_folder_name': 'Type your folder name here.',
  'dashboard.folder.rename': 'Rename Folder',
  'dashboard.folder.renamed': 'Folder is renamed.',
  'dashboard.folder.name': 'folder name',

  'dashboard.card.rename_deck': 'Rename Deck',
  'dashboard.card.transferring':
    'Your deck is transferring from old version...',
  'dashboard.card.migration': 'Migrate',
  'dashboard.card.type_deck_name': 'Type your deck name here.',
  'dashboard.card.deck_name': 'Deck name',
  'dashboard.card.deck_renamed': 'Deck is renamed.',
  'dashboard.card.deck_delete_text':
    'Are you sure you want to delete “{{deckName}}” ?',
  'dashboard.card.remove_me_text':
    'Are you sure you want to remove yourself from “{{deckName}}” ?',
  'dashboard.card.remove_me': 'Remove Me',
  'dashboard.card.file_not_recover': "Files can't be recovered once deleted.",
  'dashboard.card.deck_deleted': 'Deck is deleted',
  'dashboard.card.deck_removed': 'You are removed from the deck',
  'dashboard.card.publish_template': 'Publish Template',
  'dashboard.card.unpublish_template': 'Unpublish Template',

  'dashboard.card.update_thumbnails': 'Update Thumbnails',
  'dashboard.update_thumbnails.success':
    'Thumbnails will be updated in a minute.',
  'dashboard.update_thumbnails.error': 'Thumbnails could not be updated.',

  'deck_not_created': "Deck couldn't created.",

  'dashboard.search.search_my_decks': 'Search my decks in Decktopus',
  'dashboard.search.results_for': 'Results for “{{searchParam}}”:',
  'dashboard.search.no_results_for':
    'No results found matching <strong>{{ searchParam }}</strong>',
  'dashboard.search.try_another_search':
    'Try adjusting your search to find what you are looking for',
  'dashboard.search.return_dashboard': 'or return to dashboard',
  'dashboard.search.search_on_decktopus': 'Search templates on Decktopus',

  'dashboard.publish.success': 'Your deck is published successfully',
  'dashboard.unpublish.success': 'Your deck is unpublished successfully',
  'dashboard.publish.error': 'Deck could not published',
  'dashboard.unpublish.error': 'Deck could not unpublished',
  // Dashboard page ends

  // Form responses
  'form_response.form_responses': 'Form Responses',
  'form_response.table.download_form': 'Download Form',
  'form_response.table.deleted_question': 'Deleted Question',
  'form_response.no_answers_yet': 'No answers yet.',
  'form_response.table.avarage_score': 'Average Score',
  // Form responses end

  // Edit page
  'edit.component_fix.detail': 'Final adjustments are being made to your deck.',
  'edit.component_fix.info': 'Please wait',
  'edit.slide_menu.layout': 'layout',

  'edit.create_slide.no_custom_layout_text': `You haven't created a custom layout yet.`,
  'edit.create_slide.no_shared_layout_text': `You don't have any shared layout.`,

  'edit.color_picker.select_color': 'Select color',
  'edit.color_picker.solid': 'Solid',
  'edit.color_picker.gradient': 'Gradient',
  'edit.color_picker.theme_colors': 'Theme Colors',
  'edit.color_picker.recent_colors': 'Recent Colors',

  'edit.media.modal.image.unsplash_library': 'Unsplash library ',
  'edit.media.modal.image.title': 'Select image from <0>Unsplash library</0>',
  'edit.media.modal.title': 'Select {{type}} from library',
  'edit.media.modal.load_more': 'Load More',
  'edit.media.modal.no_result': 'No result',
  'edit.media.modal.initial_text': 'Please enter a text to search {{type}}',
  'edit.media.modal.ai.input': 'description',
  'edit.media.modal.select_media': 'Select {{ type }}',
  'edit.media.modal.search_media': 'Search {{ type }}',

  'edit.list.submenu.placeholder': 'multi',
  'edit.list.layout.columns': 'Columns',
  'edit.list.layout.rows': 'Rows',

  'edit.create_slide.copy_from_another_deck': 'Copy from another deck',
  'edit.header.title': 'Edit Header Component',

  'edit.elements.embeds': 'Embeds',
  'edit.elements.interactive_elements': 'Interactive Elements',
  'edit.elements.see_all': 'See All',
  'edit.elements.see_less': 'See Less',
  'edit.elements.shapes': 'Shapes',
  'edit.elements.tooltip_text': 'Only 1 form can be added to a slide',
  'edit.elements.content_blocks': 'Content Blocks',

  'edit.canvas.blank': 'Blank',
  'edit.canvas.context_menu.bring_forward': 'Bring Forward',
  'edit.canvas.context_menu.send_backward': 'Send Backward',
  'edit.canvas.context_menu.bring_to_front': 'Bring to Front',
  'edit.canvas.context_menu.send_to_back': 'Send to Back',
  'edit.canvas.context_menu.send_to_background': 'Send to Background',

  'edit.canvas.context_menu.edit_with_ai': 'Edit with AI',
  'edit.canvas.context_menu.edit_with_ai.summarize': 'Summarize',
  'edit.canvas.context_menu.edit_with_ai.fix': 'Fix',
  'edit.canvas.context_menu.edit_with_ai.rewrite': 'Rewrite',
  'edit.canvas.context_menu.edit_with_ai.extend': 'Extend',
  'edit.canvas.context_menu.edit_with_ai.other': 'Other',
  'edit.canvas.context_menu.edit_with_ai.generate': 'Generate',
  'edit.canvas.context_menu.edit_with_ai.prompt_placeholder':
    'Type your prompt here.',
  'edit.canvas.context_menu.edit_with_ai.title':
    'Give Decktopus a prompt to improve your selected content',
  'edit.canvas.context_menu.edit_with_ai.input_error_message':
    'Description can not be shorter than 3 characters.',
  'edit.canvas.context_menu.edit_with_ai.nothing_improved':
    'There was nothing to fix',

  'edit.canvas.paste.processing_image': 'Processing image...',
  'edit.canvas.paste.image_pasted': 'Image uploaded!',

  'edit.ai.create_qa_text':
    'See the Q&As specialized for your presentation. The questions that might be asked during your presentation and their answers are in this section.',
  'edit.ai.create_qa_button': 'Create Q&A',
  'edit.ai.create_tip_text':
    'Slide tips are in this section to provide a better presentation experience. Icebreaker, hook, and story-like structure suggestions are here to assist you.',
  'edit.ai.create_tip_button': 'Create Tips',
  'edit.ai.create_tips_hover': 'Create AI deck to use this feature.',
  'edit.ai.translate_text':
    'This feature translates only text elements. Images and visuals will not be translated.',
  'edit.ai.translate_success':
    'The presentation in {{language}} has been created.',

  'edit.canvas_sidebar.start_show': 'Start Show',
  'edit.canvas_sidebar.start_voice': 'Start Voice',
  'edit.canvas_sidebar.swap_colors': 'Swap Colors',

  'edit.design.customize_design': 'Customize Design',
  'edit.design.font_family': 'Font Family',
  'edit.design.default_fonts_delete': 'Default fonts can not be deleted.',
  'edit.design.upload_custom_font': 'Upload Custom Font',
  'edit.design.upload_your_font': 'Upload Your Font Here',
  'edit.design.allowed_fonts_text':
    'Please upload only .ttf and .otf format fonts*.',
  'edit.design.custom_font_responsibility':
    '*All responsibility for the commercial use of the uploaded fonts belongs to the user. Decktopus does not assume any liability for commercial use.',
  'edit.design.upload_primary_font': 'Upload Primary Font',
  'edit.design.upload_secondary_font': 'Upload Secondary Font',
  'edit.design.color_palette': 'Color Palette',
  'edit.design.default_color_palettes': 'Default palettes can not be deleted.',

  'edit.preferences.background_color': 'Background Color',
  'edit.preferences.background_tooltip':
    'Select a new decor below to change background.',
  'edit.preferences.background_decor': 'Background Decor',

  'edit.properties.list.submenu.title': 'Text & Icon Properties',
  'edit.properties.list.new_list.add_new': 'Add New',

  'edit.properties.quote.alignment': 'Quote Alignment',
  'edit.properties.quote.placeholder': 'Quote',
  'edit.properties.quote.author': 'Author',
  'edit.properties.quote.size.label.text': 'Quote Text',
  'edit.properties.quote.size.label.author': 'Author',

  'edit.properties.media.image_props': 'Image Properties',
  'edit.properties.media.image_opacity': 'Opacity',
  'edit.properties.media.corner_radius': 'Corner Radius',

  'edit.properties.icon.styles': 'Styles',
  'edit.properties.icon.icon': 'Icon',
  'edit.properties.icon.background': 'Background',

  'edit.properties.data': 'Edit Data',
  'edit.properties.table': 'Edit Table',
  'edit.properties.show_header_column': 'Show Header Columnn',
  'edit.properties.show_header_row': 'Show Header Row',
  'edit.properties.form.submenu.title': 'Form Properties',
  'edit.properties.form.button_text': 'button text',
  'edit.properties.form.placeholder_text': 'Text placeholder',
  'edit.properties.form.question_text': 'question text',
  'edit.properties.form.choice': 'choice',
  'edit.properties.form.header': 'Add Here Form Type',
  'edit.properties.form.header.button_text': 'Button Text',
  'edit.properties.form.option_list': 'Option List',
  'edit.properties.form.check_list': 'Check List',
  'edit.properties.form.free_text': 'Free Text',
  'edit.properties.form.submitting_required_tooltip':
    'If this is on, the viewer cannot see the next slides until submitting this form',

  'edit.properties.button_props.paste_url': 'Paste a website url.',
  'edit.properties.button_props.outlined': 'Outlined',

  'edit.properties.chart_props.show_grid': 'Show Grid Lines',
  'edit.properties.chart_props.show_legends': 'Show Legends',
  'edit.properties.chart_props.show_background': 'Show Background',
  'edit.properties.chart_props.settings': 'Settings',
  'edit.properties.chart_props.opacity': 'Background Opacity',
  'edit.properties.chart_props.background_color': 'Background Color',

  'edit.properties.chart_title.placeholder': 'Chart Title',
  'edit.properties.edit_data.error': 'not a number',

  'edit.properties.x_axis.show_x_axis': 'Show X Axis',
  'edit.properties.x_axis.x_axis': 'X Axis',
  'edit.properties.y_axis.show_y_axis': 'Show Y Axis',
  'edit.properties.y_axis.y_axis': 'Y Axis',

  'edit.properties.form_choice.choice': 'Choice ',

  'edit.properties.form_props.question': 'Question ',
  'edit.properties.form_props.description': 'Description',
  'edit.properties.form_props.choice_1': 'Choice 1',
  'edit.properties.form_props.choice_2': 'Choice 2',
  'edit.properties.form_props.choice_3': 'Choice 3',

  'edit.properties.form_question_option.add_choice': 'Add Choice',
  'edit.properties.form_question_rate.choose_icon': 'Choose Icon Style',

  'edit.properties.device.header': 'Link',
  'edit.properties.device.change_device': 'Change Device',
  'edit.properties.device.laptop': 'Laptop',
  'edit.properties.device.tablet': 'Tablet',
  'edit.properties.device.phone': 'Phone',

  'edit.properties.chart.chart_data': 'Chart Type:',

  'edit.properties.shape.placeholder': 'Shape',
  'edit.properties.shape.distort': 'Distort',

  'edit.properties.ai_image.create': 'Create with AI',
  'edit.properties.ai_image.generate_with_ai': 'Generate an Image with AI',
  'edit.properties.ai_image.describe': 'Describe your request to AI',
  'edit.properties.ai_image.generate': 'Generate',

  'edit.properties.table.placeholder': 'Settings',

  'edit.properties.text_size.placeholder': 'Multi',
  'edit.properties.text_size.big_text': 'Big Text',
  'edit.properties.text_size.title': 'Title',
  'edit.properties.text_size.subtitle': 'Subtitle',
  'edit.properties.text_size.body': 'Body',
  'edit.properties.text_size.label': 'Label',

  'edit.properties.text_props.header': 'Text',
  'edit.properties.text_props.reset': 'Reset',
  'edit.properties.text_props.fill': 'Fill',
  'edit.properties.text_props.opacity': 'Opacity',

  'edit.properties.video_props.video': 'Video',
  'edit.properties.video_props.paste': 'Paste Youtube or Vimeo video link.',

  'edit.properties.website_props.website': 'Website',
  'edit.properties.website_props.paste': 'Paste a website url.',

  'edit.properties.alignment.position': 'Position',

  'edit.properties.list.list_type': 'List Type',
  'edit.properties.list.list_direction': 'Direction',
  'edit.properties.list.media_position': 'Media Position',
  'edit.properties.list.layout': 'Layout',
  'edit.properties.list.colors': 'Colors',
  'edit.properties.list.font_size': 'Font Size',
  'edit.properties.list.size': 'Size',
  'edit.properties.list.icon_frame': 'Icon Frame',
  'edit.properties.list.color.icon': 'Icon',
  'edit.properties.list.color.icon_frame': 'Frame',
  'edit.properties.list.color.number': 'Number',
  'edit.properties.list.color.bullet': 'Bullet',
  'edit.properties.list.font_title': 'Title',
  'edit.properties.list.color.title': 'Title',
  'edit.properties.list.color.body': 'Body',
  'edit.properties.list.font_body': 'Body',
  'edit.properties.list.font_logo': 'Logo',

  'edit.properties.form.form_properties': 'Form Properties',
  'edit.properties.form.gdpr_message': 'GDPR Message',
  'edit.properties.form.submitting_required': 'Form Submitting is Required',
  'edit.properties.form.consent': 'Enable GDPR Consent',
  'edit.properties.form.gdpr_tooltip':
    'If this is on, a checkbox appears above the submit button.',
  'edit.properties.form.gdpr_prop_placeholder': 'Free Text Input Placeholder',
  'edit.properties.form.option_list_placeholder': 'Option List Placeholder',
  'edit.properties.form.rate_placeholder': 'Rate Placeholder',
  'edit.properties.form.back_button_text': 'Back Button Text',
  'edit.properties.form.next_button_text': 'Next Button Text',
  'edit.properties.form.submit_button_text': 'Submit Button Text',
  'edit.properties.form.submit_message': 'Submit Message',
  'edit.properties.font.select_option': 'Select Option',

  'edit.add_new_slide_modal.slide_layouts': 'Slide Layouts',
  'edit.add_new_slide_modal.custom_layouts': 'Custom Layouts',
  'edit.add_new_slide_modal.search_slide_type':
    'Search a slide type from your layout library e.g. “Image”',

  'edit.panel.menu_tabs.slide_notes': 'Slide Notes',
  'edit.panel.menu_tabs.transcripts': 'Transcripts',
  'edit.panel.menu_tabs.tips': 'Tips',
  'edit.panel.menu_tabs.qa': 'Q&A',
  'edit.panel.menu_tabs.translate': 'Translate',
  'edit.panel.menu_tabs.themes': 'Themes',
  'edit.panel.menu_tabs.style': 'Style',
  'edit.panel.menu_tabs.colors': 'Colors',

  'edit.panel.background': 'Background',
  'edit.panel.elements': 'Elements',
  'edit.panel.notes': 'Notes',
  'edit.panel.ai_assistant': 'AI Assistant',
  'edit.panel.brand': 'Brand',
  'edit.panel.storage': 'Storage',
  'edit.panel.layers': 'Layers',

  'edit.slide_menu.warning': 'Warning',
  'edit.slide_menu.warning_text':
    'You are about to replace an existing layout, do you want to continue?',
  'edit.slide_menu.replace': 'Replace',

  'edit.properties.zoom_types.fill': 'Fill',
  'edit.properties.zoom_types.fit': 'Fit',
  'edit.properties.zoom_types.distort': 'Distort',
  'edit.properties.zoom_types.crop': 'Crop',

  'edit.definitions.bar_chart.display_name': 'Bar Chart',
  'edit.definitions.bar_chart.name1': 'Engineering',
  'edit.definitions.bar_chart.name2': 'Marketing',
  'edit.definitions.bar_chart.name3': 'Operation',
  'edit.definitions.body.text': 'New Body',
  'edit.definitions.body.display_name': 'This is your body',
  'edit.definitions.bullet_list.display_name': 'Bullet List',
  'edit.definitions.bullet_list.item_title1': 'Item 1',
  'edit.definitions.bullet_list.item_title2': 'Item 2',
  'edit.definitions.bullet_list.item_title3': 'Item 3',
  'edit.definitions.bullet_list.item_body1': 'Description of Item 1',
  'edit.definitions.bullet_list.item_body2': 'Description of Item 2',
  'edit.definitions.bullet_list.item_body3': 'Description of Item 3',
  'edit.definitions.button.display_name': 'Button',
  'edit.definitions.button.text': 'Button',
  'edit.definitions.code.display_name': 'Code',
  'edit.definitions.device.display_name': 'Device',
  'edit.definitions.donut_chart.display_name': 'Donut Chart',
  'edit.definitions.donut_chart.label': 'Series1',
  'edit.definitions.donut_chart.value1': 'Alpha',
  'edit.definitions.donut_chart.value2': 'Bravo',
  'edit.definitions.donut_chart.value3': 'Charlie',
  'edit.definitions.donut_chart.value4': 'Delta',
  'edit.definitions.donut_chart.value5': 'Echo',
  'edit.definitions.form.display_name': 'Form',
  'edit.definitions.form.title': 'Form Title',
  'edit.definitions.form.button_text': 'Submit',
  'edit.definitions.form.answers.text': 'Text type placeholder',
  'edit.definitions.form.answers.option': 'Option type placeholder',
  'edit.definitions.form.answers.rate': 'Rate type placeholder',
  'edit.definitions.form.buttons.next': 'Next',
  'edit.definitions.form.buttons.back': 'Back',
  'edit.definitions.form.submit': 'Thank you',
  'edit.definitions.form.gdpr_consent_message': 'GDPR Message',
  'edit.definitions.form.question1': 'Question 1',
  'edit.definitions.form.question2': 'Question 2',
  'edit.definitions.form.question3': 'Question 3',
  'edit.definitions.form.question2.description': 'Description',
  'edit.definitions.form.choice1': 'Choice 1',
  'edit.definitions.form.choice2': 'Choice 2',
  'edit.definitions.form.choice3': 'Choice 3',
  'edit.definitions.gif.display_name': 'Gif',
  'edit.definitions.icon.display_name': 'Icon',
  'edit.definitions.icon_list.display_name': 'Icon List',
  'edit.definitions.icon_list.title1': 'Item 1',
  'edit.definitions.icon_list.body1': 'Description of Item 1',
  'edit.definitions.icon_list.title2': 'Item 2',
  'edit.definitions.icon_list.body2': 'Description of Item 2',
  'edit.definitions.icon_list.title3': 'Item 3',
  'edit.definitions.icon_list.body3': 'Description of Item 3',
  'edit.definitions.image.display_name': 'Image',
  'edit.definitions.image_grid.display_name': 'Image Grid',
  'edit.definitions.image_grid.title1': 'Item 1',
  'edit.definitions.image_grid.body1': 'Description of Item 1',
  'edit.definitions.image_grid.title2': 'Item 2',
  'edit.definitions.image_grid.body2': 'Description of Item 2',
  'edit.definitions.image_grid.title3': 'Item 3',
  'edit.definitions.image_grid.body3': 'Description of Item 3',
  'edit.definitions.image_list.display_name': 'Image List',
  'edit.definitions.image_list.title1': 'Item 1',
  'edit.definitions.image_list.body1': 'Description of Item 1',
  'edit.definitions.image_list.title2': 'Item 2',
  'edit.definitions.image_list.body2': 'Description of Item 2',
  'edit.definitions.image_list.title3': 'Item 3',
  'edit.definitions.image_list.body3': 'Description of Item 3',
  'edit.definitions.jumbo.text': 'New Jumbo Text',
  'edit.definitions.jumbo.display_name': 'This is big text',
  'edit.definitions.label.text': 'New Label',
  'edit.definitions.label.display_name': 'This is your label',
  'edit.definitions.line_chart.display_name': 'Line Chart',
  'edit.definitions.line_chart.label1': 'Line 1',
  'edit.definitions.line_chart.label1.name1': '09 Feb',
  'edit.definitions.line_chart.label1.name2': '10 Feb',
  'edit.definitions.line_chart.label1.name3': '11 Feb',
  'edit.definitions.line_chart.label1.name4': '12 Feb',
  'edit.definitions.line_chart.label1.name5': '13 Feb',
  'edit.definitions.line_chart.label2': 'Line 2',
  'edit.definitions.line_chart.label2.name1': '09 Feb',
  'edit.definitions.line_chart.label2.name2': '10 Feb',
  'edit.definitions.line_chart.label2.name3': '11 Feb',
  'edit.definitions.line_chart.label2.name4': '12 Feb',
  'edit.definitions.line_chart.label2.name5': '13 Feb',
  'edit.definitions.links.display_name': 'Link',
  'edit.definitions.links.text': 'Button text',
  'edit.definitions.logo.display_name': 'Logo',
  'edit.definitions.logo_list.display_name': 'Logo List',
  'edit.definitions.logo_list.title1': 'Logo 1',
  'edit.definitions.logo_list.body1': 'Description of Logo 1',
  'edit.definitions.logo_list.title2': 'Logo 2',
  'edit.definitions.logo_list.body2': 'Description of Logo 2',
  'edit.definitions.logo_list.title3': 'Logo 3',
  'edit.definitions.logo_list.body3': 'Description of Logo 3',
  'edit.definitions.pie_chart.display_name': 'Pie Chart',
  'edit.definitions.pie_chart.label': 'Series 1',
  'edit.definitions.pie_chart.value1': 'Alpha',
  'edit.definitions.pie_chart.value2': 'Bravo',
  'edit.definitions.pie_chart.value3': 'Charlie',
  'edit.definitions.pie_chart.value4': 'Delta',
  'edit.definitions.pie_chart.value5': 'Echo',
  'edit.definitions.quote.display_name': 'Quote',
  'edit.definitions.quote.quote': `Imagination is everything. It is the preview of life's coming attractions. `,
  'edit.definitions.quote.author': 'Albert Einstein',
  'edit.definitions.rating.display_name': 'Rating',
  'edit.definitions.rating.title': 'Form Title',
  'edit.definitions.rating.button_text': 'Submit',
  'edit.definitions.rating.placeholder.text': 'Text type placeholder',
  'edit.definitions.rating.placeholder.options': 'Option type placeholder',
  'edit.definitions.rating.placeholder.rate': 'Rate type placeholder',
  'edit.definitions.rating.next': 'Next',
  'edit.definitions.rating.back': 'Back',
  'edit.definitions.rating.submit': 'Thank you',
  'edit.definitions.rating.gdpr_consent': 'GDPR Message',
  'edit.definitions.rating.question_text': 'Rate your experience!',
  'edit.definitions.rating.question_description': 'Description',
  'edit.definitions.subtitle.text': 'New Subtitle',
  'edit.definitions.subtitle.display_name': 'This is your subtitle',
  'edit.definitions.showcase.display_name': 'Showcase',
  'edit.definitions.table.display_name': 'Table',
  'edit.definitions.table.header1': 'Categories',
  'edit.definitions.table.header2': 'Values 1',
  'edit.definitions.table.header3': 'Values 2',
  'edit.definitions.table.header4': 'Values 3',
  'edit.definitions.table.header5': 'Values 4',
  'edit.definitions.table.row1.value1': 'Categories 1',
  'edit.definitions.table.row1.value2': '30',
  'edit.definitions.table.row1.value3': '40',
  'edit.definitions.table.row1.value4': '50',
  'edit.definitions.table.row1.value5': '60',
  'edit.definitions.table.row2.value1': 'Categories 2',
  'edit.definitions.table.row2.value2': '10',
  'edit.definitions.table.row2.value3': '20',
  'edit.definitions.table.row2.value4': '30',
  'edit.definitions.table.row2.value5': '40',
  'edit.definitions.table.row3.value1': 'Categories 3',
  'edit.definitions.table.row3.value2': '7',
  'edit.definitions.table.row3.value3': '23',
  'edit.definitions.table.row3.value4': '100',
  'edit.definitions.table.row3.value5': '70',
  'edit.definitions.table.row4.value1': 'Categories 4',
  'edit.definitions.table.row4.value2': '97',
  'edit.definitions.table.row4.value3': '2',
  'edit.definitions.table.row4.value4': '13',
  'edit.definitions.table.row4.value5': '55',
  'edit.definitions.timeline.display_name': 'Timeline',
  'edit.definitions.timeline.item1': 'Item 1',
  'edit.definitions.timeline.description1': 'Description of Item 1',
  'edit.definitions.timeline.item2': 'Item 2',
  'edit.definitions.timeline.description2': 'Description of Item 2',
  'edit.definitions.timeline.item3': 'Item 3',
  'edit.definitions.timeline.description3': 'Description of Item 3',
  'edit.definitions.title.text': 'New Title',
  'edit.definitions.title.display_name': 'This is your title',
  'edit.definitions.video.display_name': 'Video',
  'edit.definitions.website.display_name': 'Website',

  'edit.background.options': 'Background Options',

  'edit.color_mapping.swap_colors': 'Swap Colors',
  'edit.color_mapping.restore': 'Restore to Default Colors',
  'edit.color_mapping.restore_short': 'Restore Colors',
  'edit.color_mapping.texts': 'Texts',
  'edit.color_mapping.texts.item1': 'Big Text',
  'edit.color_mapping.texts.item2': 'Title',
  'edit.color_mapping.texts.item3': 'Subtitle',
  'edit.color_mapping.texts.item4': 'Body',
  'edit.color_mapping.texts.item5': 'Label',
  'edit.color_mapping.background': 'Background',
  'edit.color_mapping.quote': 'Quote',
  'edit.color_mapping.quote.item1': 'Quote',
  'edit.color_mapping.quote.item2': 'Author',
  'edit.color_mapping.icons': 'Icons',
  'edit.color_mapping.icons.item1': 'Icon',
  'edit.color_mapping.icons.item2': 'Icon Fill',
  'edit.color_mapping.lists': 'Lists',
  'edit.color_mapping.lists.item1': 'List Bullet',
  'edit.color_mapping.lists.item2': 'List Title',
  'edit.color_mapping.lists.item3': 'List Body',
  'edit.color_mapping.table': 'Table',
  'edit.color_mapping.table.item1': 'Content',
  'edit.color_mapping.table.item2': 'Highlight',
  'edit.color_mapping.buttons': 'Buttons',
  'edit.color_mapping.buttons.item1': 'Text',
  'edit.color_mapping.buttons.item2': 'Button Fill',
  'edit.color_mapping.buttons.item3': 'Border',
  'edit.color_mapping.timeline': 'Timeline',
  'edit.color_mapping.timeline.item1': 'Timeline Bar',
  'edit.color_mapping.timeline.item2': 'Timeline Dot',
  'edit.color_mapping.form': 'Form',
  'edit.color_mapping.form.item1': 'Question',
  'edit.color_mapping.form.item2': 'Button Text',
  'edit.color_mapping.form.item3': 'Button Fill',
  'edit.color_mapping.form.item4': 'Icon',
  'edit.color_mapping.chart': 'Chart',
  'edit.color_mapping.chart.items': 'Color',
  'edit.color_mapping.chart.background': 'Chart Background',
  'edit.color_mapping.text_highlight': 'Text highlight',
  'edit.color_mapping.text_highlight.item1': 'Highlight',
  'edit.color_mapping.text_highlight.item2': 'Highlight Background',
  'edit.color_mapping.shape': 'Shape',
  'edit.color_mapping.shape.item1': 'Shape Fill',
  'edit.color_mapping.decoration': 'Decorations',
  'edit.color_mapping.decoration.items': 'Decoration',
  // Edit page ends

  // User classify page
  'user_classify.business': 'Business',
  'user_classify.continue_with_decktopus': 'Continue with Decktopus',
  'user_classify.customer_service': 'Customer Service',
  'user_classify.education': 'Education',
  'user_classify.educator': 'Educator',
  'user_classify.engineering': 'Engineering',
  'user_classify.finance': 'Finance',
  'user_classify.friends': 'Friends',
  'user_classify.google_search': 'Google Search',
  'user_classify.hr': 'HR',
  'user_classify.legal': 'Legal',
  'user_classify.management': 'Management',
  'user_classify.other': 'Other',
  'user_classify.product': 'Product',
  'user_classify.sales_marketing': 'Sales Marketing',
  'user_classify.see_other_options': 'See other options',
  'user_classify.social_media': 'Social Media',
  'user_classify.student': 'Student',
  'user_classify.tell_us_about_yourself': 'Tell us about yourself',
  'user_classify.classify_question':
    'For what purpose are you using Decktopus?',
  'user_classify.business_question': 'What kind of work do you do?',
  'user_classify.how_to_hear_about_us': 'How did you hear about us?',
  // User classify page ends

  // Share modal
  'share_modal.embed.code': 'Get Embed Code',
  'share_modal.share_link': 'Share Link',
  'share_modal.get_share_link': 'Get Share Link',
  'share_modal.make_the_share_link_public': 'Make the share link public',
  'share_modal.select_from_list': 'Select from user list',

  'share_modal.share_link_updated': 'Share link updated successfully.',
  'share_modal.invite_people_to_collaborate': 'Invite People to Collaborate',
  'share_modal.invitations_sent': 'Invitations sent',
  'share_modal.organization_deck_permission': 'Organization Permission',
  'share_modal.valid_email_message':
    'Please enter a valid email address and hit enter',
  'share_modal.add_collaborators': 'Add Collaborator',
  'share_modal.not_collaborating_message':
    'You are not collaborating with anyone, yet.',
  'share_modal.collaborators': 'Collaborators',
  'share_modal.can_edit': 'Can Edit',
  'share_modal.can_view': 'Can View',
  'share_modal.embed_code': 'Embed Code',
  'share_modal.collaborated_users_updated': 'Collaborated users updated',
  'share_modal.save_complete': 'Changes are saved',
  'share_modal.pdf_export': 'Export PDF',
  'share_modal.pptx_export': 'Export PPTX',
  'share_modal.latest_exports': 'My latest Exports',
  'share_modal.compressed_export_message':
    'Compression reduces file size by combining text, images, and other components into optimized images for each slide, making storage and sharing easier.',
  'share_modal.include_slide_notes': 'Include slide notes',
  'share_modal.include_slide_notes_info_tooltip':
    'Notes will be added under the page thumbnail.',

  'share_modal.custom_domain.status': 'Status',
  'share_modal.custom_domain.host': 'Host',
  'share_modal.custom_domain.completed': 'COMPLETED',
  'share_modal.custom_domain.pending': 'PENDING',
  'share_modal.custom_domain.record': 'Record',
  'share_modal.custom_domain.data': 'Data',
  'share_modal.custom_domain.cname': 'CNAME',
  'share_modal.custom_domain.share_link_placeholder':
    'e.g. https://document.yourdomain.com',
  'share_modal.custom_domain.domain_successfully': 'Domain saved successfully.',
  'share_modal.custom_domain.domain_deleted': 'Domain deleted successfully.',
  'share_modal.custom_domain.domain_failed':
    'The domain has not been verified yet. It may take up to 72 hours depending on your domain server',
  'share_modal.custom_domain.dns_record':
    'To connect your custom domain to your Decktopus, you need to update your DNS records based on the table below.',
  'share_modal.custom_domain.add_your_subdomain': 'Add your subdomain:',
  'share_modal.custom_domain.info': 'Info',
  'share_modal.custom_domain.verified': 'Verified',
  'share_modal.custom_domain.unverified': 'Unverified',
  'share_modal.custom_domain.connect_your_custom_domain':
    'Connect Your Custom Domain',
  // Share modal ends

  // Admin page
  'admin.menu.user_info': 'User Info',
  'admin.menu.themes': 'Themes',
  'admin.menu.back_to_search': 'Back to Search',
  'admin.search.welcome_support_panel':
    'Welcome to the <strong>Support Panel</strong>',
  'admin.search.you_can_search_by':
    'You can search by email, deck id or share key to list what you need.',
  'admin.search.share_key': 'Share Key',
  'admin.search.deck_id': 'Deck ID',
  'admin.search.user_not_found': 'User not found.',
  'admin.table.e_mail': 'Email',
  'admin.table.delete_user': 'Delete User',
  'admin.table.created_date': 'Created Date',
  'admin.confirm.cannot_undo_delete_user':
    'You can not undo this function. Are you sure you want to delete this user?',
  'admin.table.actions': 'Actions',
  'admin.action_menu.login_as_user': 'Login as user',
  'admin.action_menu.gift': 'Gift',
  'admin.action_menu.reset_password': 'Reset password',
  'admin.action_menu.change_email': 'Change email',
  'admin.action_menu.update_seat_size': 'Update seat size',
  'admin.update_email.current_email': 'Current email',
  'admin.update_email.new_email': 'New email',
  'admin.update_email.provider': 'Provider',
  'admin.update_email.confirm': 'Confirm',
  'admin.update_email.success': 'Email updated successfully.',
  'admin.reset_password.are_you_sure':
    'Are you sure you want to reset the password?',
  'admin.reset_password.link_sent':
    "A password reset link has been sent to the user's email.",
  'admin.reset_password.link_copied': 'Link copied to clipboard',
  'admin.gift_user_success': 'Credit gifted successfully.',
  'admin.gift_credit_amount': 'Credit Amount',
  'admin.gift_confirm_amount': 'Confirm credit amount',
  'admin.dashboard.header.back_to_support': 'Back to Support panel',
  'admin.dashboard.header.logged_in_as_user': 'You have logged in as a user.',
  // Admin page ends

  // Table component menu items
  'table.add_row_above': 'Add row above',
  'table.add_row_below': 'Add row below',
  'table.add_column_left': 'Add column left',
  'table.add_column_right': 'Add column right',
  'table.delete_row': 'Delete row',
  'table.delete_column': 'Delete column',
  // Table component menu items ends

  // Payment confirmation modal
  'payment_confirmation.title': 'Upgrade your plan to {{ planName }}',
  'payment_confirmation.subtitle':
    'You are just a step away from enjoying Decktopus’s exclusive features',
  'payment_confirmation.plan': 'Plan:',
  'payment_confirmation.payment_method': 'Payment Method:',
  'payment_confirmation.card_number': 'Card Number:',
  'payment_confirmation.card_date': 'Card Date:',
  'payment_confirmation.total': 'Total:',
  'payment_confirmation.contact_us':
    'If you have any questions or need assistance, feel free to <0>contact us.</0>',
  'payment_confirmation.cancel': 'Cancel Payment',
  'payment_confirmation.confirm': 'Confirm and Pay',
  'payment_confirmation.seat': 'Seat',
  'payment_confirmation.lite': 'Lite AI',
  'payment_confirmation.pro': 'Pro AI',
  'payment_confirmation.business': 'Business AI',
  'payment_confirmation.remaining_line':
    'Remaining time on {{ planName }} after {{ date }}',
  'payment_confirmation.unused_line':
    'Unused time on {{ planName }} after {{ date }}',
  'payment_confirmation.total_tooltip':
    'The payment will be prorated based on the remaining days in your current billing cycle.',
  // Payment confirmation modal ends

  // AI Loading tips
  'ai_loading.text1':
    'Did you know that people remember only 10% of what they hear after three days, but they remember 65% if a picture is added?',
  'ai_loading.text2':
    'On average, people make a decision about a presentation within the first 90 seconds.',
  'ai_loading.text3':
    'Visual aids in a presentation can improve learning by up to 400%.',
  'ai_loading.text4':
    'The longest presentation ever lasted for 124 hours and was delivered by Arvind Mishra in India in 2018.',
  'ai_loading.text5':
    'Using high-contrast colors in your slides can increase readability by up to 78%.',
  'ai_loading.text6':
    'Did you know? 55% of communication is body language, 38% is tone of voice, and only 7% is the actual words spoken.',
  'ai_loading.text7':
    'The average person’s attention span is just 8 seconds – make your slides count!',
  'ai_loading.text8':
    'Communication Tip: Use storytelling to make your presentations more memorable.',
  'ai_loading.text9':
    'Presentations with simpler slides tend to be more impactful.',
  'ai_loading.text10':
    'Incorporating stories into your presentation can make it 22 times more memorable.',
  'ai_loading.text11':
    'Making eye contact with your audience can increase engagement and trust.',
  'ai_loading.text12':
    'People remember only 10% of what they hear after three days, but this rate increases to 65% if a picture is added.',
  'ai_loading.text13':
    'People decide on a presentation within the first 90 seconds.',
  'ai_loading.text14': 'Visual aids can improve learning by up to 400%!',
  'ai_loading.text15':
    'The longest presentation lasted 124 hours by Arvind Mishra in India, 2018.',
  'ai_loading.text16':
    'High-contrast slide colors can boost readability by 78%.',
  'ai_loading.text17':
    '55% of communication is body language, 38% tone, and 7% words.',
  'ai_loading.text18':
    'The average attention span is 8 seconds – make your slides count!',
  'ai_loading.text19': 'Tip: Use storytelling to make presentations memorable.',
  'ai_loading.text20': 'Simpler slides often make a bigger impact.',
  'ai_loading.text21': 'Stories make presentations 22 times more memorable.',
  'ai_loading.text22': 'Eye contact increases audience engagement and trust.',

  'save_state.saving': 'Saving',
  'save_state.saved': 'Saved',
  'save_state.not_saved': 'Not saved',
  'save_state.failed': 'Save failed',

  // AI Loading tips ends
  // V3 related development texts
  'v3.transparency': 'Transparency',
  'v3.letter_spacing': 'Letter Spacing',
  'v3.line_height': 'Line Height',
  'v3.line_size': 'Line Size',
  'v3.stroke_size': 'Stroke Size',
  'v3.stroke': 'Stroke',
  'v3.fill': 'Fill',
  'v3.group': 'Group',
  'v3.ungroup': 'Ungroup',
  'v3.effects': 'Effects',
  'v3.position': 'Position',
  'v3.present': 'Present',
  'v3.flip': 'Flip',
  'v3.flip_horizontal': 'Flip Horizontally',
  'v3.flip_vertical': 'Flip Vertically',
  'v3.fit': 'Fit to page',
  'v3.apply_mask': 'Apply mask',
  'v3.remove_mask': 'Remove mask',
  'v3.colors': 'Colors',
  'v3.corner_radius': 'Corner radius',
  'v3.trim': 'Trim',
  'v3.preparing': 'Preparing',
  'v3.volume': 'Volume',
  'v3.text.bigtext': 'Big text',
  'v3.text.heading': 'Heading',
  'v3.text.subheading': 'Subheading',
  'v3.text.normaltext': 'Normal text',
  'v3.text.smalltext': 'Small text',
  'v3.text.ai_write': 'AI Write',
  'v3.media.replace': 'Replace',
  // v3 media uploader
  'v3.media_uploader.file_size_error':
    'File size must be less than {{ maxSize }}MB',
  'v3.media_uploader.file_type_error': 'File type not allowed',
  'v3.media_uploader.allowed_extensions_error':
    'Allowed extensions: {{ extensions }}',
  'v3.media_uploader.file_upload_error':
    'File upload error. Please try again later',
  'v3.media_uploader_file_limit_error':
    'Maximum upload limit is 10 files. Please try again.',
  // v3 media uploader ends
  'v3.panel.content_blocks': 'Content Blocks',
  'v3.panel.timelines': 'Timelines',
  'v3.panel.diagrams': 'Diagrams',
  'v3.panel.image_blocks': 'Image Blocks',
  'v3.panel.lists': 'Lists',
  'v3.panel.icon_blocks': 'Icon Blocks',
  'v3.panel.galleries': 'Galleries',
  'v3.panel.shapes_lines': 'Shapes and Lines',
  'v3.panel.notes.placeholder': 'Add slide notes...',
  'v3.add_page': 'Add page',
  'v3.delete_page': 'Delete page',
  'v3.duplicate_page': 'Duplicate page',
  'v3.object_locked':
    'Object is locked. Unlock it to allow changes from canvas.',
  'v3.object_unlocked':
    'Object is unlocked. Lock it to prevent changes from canvas.',
  'v3.duplicate_elements': 'Duplicate elements',
  'v3.delete_elements': 'Delete elements',
  'v3.images': 'Images',
  'v3.icons': 'Icons',
  'v3.videos': 'Videos',
  'v3.gifs': 'GIFs',
  'v3.see_all': 'See all',
  'v3.trending': 'Trending',
  'v3.results': '"{{search}}" results',
  'v3.font': 'Font',
  'v3.style': 'Style',
  'v3.background': 'Background',
  'v3.style.palette': 'Theme palette',
  'v3.style.theme_elements_colors': 'Theme Elements Colors',
  'v3.style.background': 'Background Color',
  'v3.style.icon': 'Icons',
  'v3.style.timeline': 'Timeline Bar and Dot',
  'v3.style.listBullet': 'List Bullet',
  'v3.style.shape': 'Shape Colors',
  'v3.style.line': 'Line Colors',
  'v3.style.builder': 'Style Builder',
  'v3.toolbar.width': 'Width (px)',
  'v3.toolbar.height': 'Height (px)',
  'v3.toolbar.resize': 'Resize',
  'v3.toolbar.x': 'X position',
  'v3.toolbar.y': 'Y position',
  'v3.toolbar.size_and_position': 'Size and Position',
  'v3.activate': 'Activate New Edit Page',
  'v3.activated': 'New editor is active',
  'v3.try_new_editor': 'Try New Editor',
  'v3.revert_editor': 'Use Old Editor',
  'v3.try_new_editor_info':
    'Click here for more detailed information about the new editor.',
  'v3.add_slide.search_placeholder':
    'Search a slide type from our layout library e.g. “Image”',
  'v3.add_slide.all': 'All',
  'v3.add_slide.text_slides': 'Text Slides',
  'v3.add_slide.media': 'Media',
  'v3.add_slide.lists': 'Lists',
  'v3.add_slide.devices': 'Devices',
  'v3.add_slide.timeline': 'Timeline',
  'v3.add_slide.icon': 'Icon',
  'v3.add_slide.quote': 'Quote',
  'v3.style.theme_fonts': 'Theme Fonts',
  'v3.font.weight100': 'Thin',
  'v3.font.weight200': 'Extra Light',
  'v3.font.weight300': 'Light',
  'v3.font.weight400': 'Normal',
  'v3.font.weight500': 'Medium',
  'v3.font.weight600': 'Semibold',
  'v3.font.weight700': 'Bold',
  'v3.font.weight800': 'Extra bold',
  'v3.font.weight900': 'Black',
  'v3.upload.media': 'Upload Media',
  'v3.upload.font': 'Upload Your Font',
  'v3.uploading': 'Uploading...',
  'v3.font.uploaded': 'Font uploaded successfully',
  'v3.style.name': 'Style Name',
  'v3.style.name_warning': 'Please name your style first',
  'v3.style.success': 'Style is saved successfully',
  'v3.style.error': 'Style saving is failed, an error has occurred.',
  'v3.style.background.uploaded':
    "Image uploaded, don't forget to save your style changes.",
  'v3.style.background.label': 'Background Images',
  'v3.style.background.colors_label': 'Background Colors',
  'v3.style.background.info':
    'You can upload an image to use as a background on your slide.',
  'v3.style.background.add': 'Add background image',
  'v3.style.palette.tooltip':
    'Here all the colors used in the theme will appear together.',
  'v3.style.elements.tooltip':
    'You can choose which color each element will use here.',
  'v3.style.default_style_error':
    "You can't delete the default style. Change default style and try again.",
  'v3.theme.layouts_all_added': 'All layouts have been added.',
  'v3.stack': 'Stack',
  'v3.unstack': 'Unstack',
  'v3.stack_props': 'Stack props',
  'v3.stack.gap': 'Gap',
  'v3.stack.align_items': 'Align items',
  'v3.solid': 'Solid',
  'v3.gradient': 'Gradient',
  'v3.uploaded_files': 'Uploaded Files',
  'v3.ai_images': 'AI Images',
  'v3.panel.tables': 'Tables',
  'v3.toolbar.table_options': 'Table Settings',
  'v3.toolbar.table_cell_options': 'Cell Style',
  'v3.toolbar.table_header_options': 'Header Style',
  'v3.toolbar.table_cell': 'Cell',
  'v3.toolbar.table_header': 'Header',
  'v3.toolbar.top': 'Top',
  'v3.toolbar.right': 'Right',
  'v3.toolbar.left': 'Left',
  'v3.toolbar.bottom': 'Bottom',
  'v3.toolbar.table_cell_bg': 'Cell background',
  'v3.toolbar.table_header_bg': 'Header background',
  'v3.toolbar.text_color': 'Text color',
  'v3.toolbar.color_style': 'Color style',
  'v3.text.tabletext': 'Table texts',
  'v3.toolbar.table': 'Table',
  'v3.toolbar.stroke_color': 'Stroke color',
  'v3.toolbar.table_headers': 'Headers',
  'v3.style.advanced_settings': 'Advanced Settings',
  'v3.table.add_row': 'Add Row',
  'v3.table.delete_row': 'Delete Row',
  'v3.table.duplicate_row': 'Duplicate Row',
  'v3.table.sort_asc': 'Sort Ascending',
  'v3.table.sort_desc': 'Sort Descending',
  'v3.table.add_column': 'Add Column',
  'v3.table.delete_column': 'Delete Column',
  'v3.table.duplicate_column': 'Duplicate Column',
  'v3.table.move_up': 'Move Up',
  'v3.table.move_left': 'Move Left',
  'v3.table.move_right': 'Move Right',
  'v3.table.move_down': 'Move Down',
  'v3.table.header_positions': 'Header Positions',
  'v3.table.border_style': 'Border Style',
  'v3.style.tableHighlight': 'Table Background',
  'v3.style.tableCell': 'Table Cell Background',
  'v3.style.tableHeader': 'Table Header Background',
  'v3.style.tableStroke': 'Table Border Color',
  'v3.ai_assistant.tips_title': 'Smart Presentation Tips',
  'v3.ai_assistant.tips_desc':
    'Get AI-powered guidance on delivery and presentation skills.',
  'v3.ai_assistant.qa_title': 'Create Engaging Q&A',
  'v3.ai_assistant.qa_desc':
    'Prepare for audience questions with tailored answers.',
  'v3.ai_assistant.translate_title': 'Translate My Deck',
  'v3.ai_assistant.translate_desc':
    'Translate your presentation seamlessly into any language.',
  'v3.ai_assistant.generate_visual_title': 'AI Image Generator',
  'v3.ai_assistant.generate_visual_desc':
    'Create stunning visuals from simple text input.',
  'v3.ai_assistant.ai_slide_title': 'AI Slide Creator',
  'v3.ai_assistant.ai_slide_desc':
    'Generate polished slides instantly from your text and files.',
  'v3.ai_assistant.aim': 'What is your aim?',
  'v3.ai_assistant.audience': 'Who is your audience?',
  'v3.ai_assistant.describe_your_request': 'Describe your request to AI',
  'v3.ai_assistant.whats_on_your_mind': "What's on your mind?",
  'v3.decktogpt.welcome_name': 'Welcome, ',
  'v3.decktogpt.what_do_you_want_to_create': 'What do you want to create?',
  'v3.decktogpt.show_examples': 'Show examples',
  'v3.decktogpt.hide_examples': 'Hide examples',
  'v3.decktogpt.drop_files_here': 'Drop files here to add to presentation',
  'v3.decktogpt.drop_files_here_desc':
    'Max 5 files per chat/presentation at 5 MB each',
  'v3.decktogpt.i_want_to_create': 'I want to create a presentation about...',
  'v3.decktogpt.presentation_outline': 'Presentation Outline',
  'v3.decktogpt.craft_the_perfect':
    'Craft the perfect presentation outline effortlessly',
  'v3.decktogpt.modify_outline': 'Modify Outline',
  'v3.decktogpt.modify': 'Modify',
  'v3.decktogpt.regenerate': 'Regenerate',
  'v3.decktogpt.theme_previews': 'Theme Previews',
  'v3.decktogpt.which_theme': 'Which theme would you like to use?',
  'v3.decktogpt.default': 'Default',
  'v3.decktogpt.unlock_fully_branded':
    'Unlock fully branded themes with our Enterprise package. Showcase your 100% corporate identity!',
  'v3.decktogpt.everything_included': 'Exclusive Enterprise Features',
  'v3.decktogpt.included_1': '100% Branded Slide Library',
  'v3.decktogpt.included_2': 'Custom Slides Tailored to You',
  'v3.decktogpt.included_3': 'Multiple Brand Kits for Every Team',
  'v3.decktogpt.included_4': 'Priority Customer Support',
  'v3.decktogpt.included_5': 'Full Alignment with Your Brand Guidelines',
  'v3.decktogpt.book_a_demo': 'Book a demo',
  'v3.decktogpt.show_theme_previews': 'Show theme previews',
  'v3.decktogpt.hide_theme_previews': 'Hide theme previews',
  'v3.decktogpt.theme_preview_tooltip':
    'These example slides preview how your presentation will look with each theme. They are not final and are meant to guide your selection.',
  'v3.decktogpt.default_tooltip':
    'Explore professionally crafted themes from Decktopus.',
  'v3.decktogpt.brand_tooltip':
    'Use your personalized theme for a unique touch.',
  'v3.decktogpt.generating_outline': 'Generating outline',
  'v3.decktogpt.searching_the_web': 'Searching the web',
  'v3.decktogpt.refine_your_presentation': 'Refine your presentation',
  'v3.decktogpt.sample_prompt_1': 'Add a slide',
  'v3.decktogpt.sample_prompt_2': 'Change the Slide 2',
  'v3.decktogpt.sample_prompt_3': 'Shorten outline',
  'v3.decktogpt.sample_prompt_4': 'Regenerate the title of Slide 2',
  'v3.decktogpt.make_concise': 'Make Concise',
  'v3.decktogpt.expand': 'Expand',
  'v3.decktogpt.theme_selection': 'Theme Selection',
  'v3.decktogpt.leave_page_prompt': 'You are about to leave DecktoGPT',
  'v3.decktogpt.best_choice': 'Best Choice',
  'v3.decktogpt.change_layout': 'Change Layout',
  'v3.decktogpt.change_design': 'Change Design',
  'v3.decktogpt.choose_a_theme': 'Choose a Theme',
  'v3.decktogpt.choose_a_theme_desc': 'That fits your presentation concept',
  'v3.decktogpt.choose_a_layout': 'Choose a Layout',
  'v3.decktogpt.apply_theme': 'Apply Theme',
  'v3.decktogpt.apply_layout': 'Apply Layout',
  'v3.decktogpt.pick_a_theme':
    'Pick a theme that best suits yout content and creates a cohesive, professional look',
  'v3.decktogpt.choose_a_layout_desc':
    'Choose a layout to organize your slide content efficiently',
  'v3.decktogpt.suggested_layouts': 'Suggested Layouts',
  'v3.decktogpt.suggested_layouts_desc':
    'These layouts suggested for your special outline content.',
  'v3.decktogpt.layouts_designed_for_you':
    'Layouts designed for your special content',
  'v3.decktogpt.others': 'Others',
  'v3.decktogpt.need_help': 'Need Help?',
  'v3.decktogpt.cover_slide': 'Cover Slide',
  'v3.decktogpt.cover_slide_desc_1':
    'A dynamic cover slide will be created based on your content.',
  'v3.decktogpt.cover_slide_desc_2':
    'You can edit the title and choose a different layout to customize it as you need.',
  'v3.decktogpt.cover_slide_desc_3':
    'If you choose layout different from Cover layouts, the outline will automatically be created again.',
  'v3.decktogpt.btn.generating_outline': 'Generating Outline',
  'v3.decktogpt.btn.creating_content': 'Creating Content',
  'v3.decktogpt.btn.selecting_layouts': 'Selecting Layouts',
  'v3.decktogpt.thinking': 'Thinking',
  'v3.decktogpt.payment_failed': 'Payment failed. Please try again later.',
  'v3.decktogpt.timeout_error': 'Something went wrong.',
  'v3.decktogpt.timeout_click_here': 'Click here to try again.',
  'v3.decktogpt.fill_missing_fields': 'Please fill in the missing fields.',
  'v3.decktogpt.ensure_layout_selected':
    'Ensure all slides have a selected layout before proceeding.',
  'v3.branding.need_fully_branded':
    'Need fully branded slides, not just colors?',
  'v3.branding.go_beyond_fonts':
    'Go beyond fonts and palettes—get custom-designed layouts built to match your brand perfectly.',
  'v3.branding.to_fully_align':
    'To fully align with your branding, please book a demo with us.',
  'v3.branding.create_your_brand': 'Create your brand',
  // V3 related development texts ends
  'v3.theme_description.aqua':
    'Light pastel colors offer a sense of clarity and tranquility.',
  'v3.theme_description.rhythm':
    'A bold and rhythmic style with structured brick patterns and rich contrasts.',
  'v3.theme_description.glint':
    'A fresh blend of green and black, balancing nature and elegance.',
  'v3.theme_description.obsidian':
    'Sleek black tones exude confidence and refined minimalism.',
  'v3.theme_description.orbit':
    'Soft pastel geometry for a modern and balanced look.',
  'v3.theme_description.nexus':
    'Deep blues and modern gradients create a futuristic touch.',
  'v3.theme_description.flow':
    'Soft, flowing waves evoke movement and effortless creativity.',
  'v3.theme_description.spark':
    'Playful and energetic with neon highlights and doodles.',
  'v3.theme_description.lusso':
    'Bold black and gold, radiating luxury and sophistication.',
  'v3.theme_description.monark':
    'Bold geometric shapes in purple and orange tones, delivering a modern and structured aesthetic.',
  'v3.theme_description.default':
    "Brand identity meets seamless design—themes that embody each brand's essence.",
  // V3 Themes description ends
}
